import styled from 'styled-components';
import StyledContainer from '@src/components/StyledContainer';

const StyledWrapper = styled(StyledContainer)`
    @media (max-width: 991px) {
        padding: 0 1rem;
    }
`;

export default StyledWrapper;
