const search = {
    title: {
        befr: 'Flyer.be | Recherche',
        benl: 'Flyer.be | Zoeken',
    },

    description: {
        befr: 'Flyer.be | Recherche',
        benl: 'Flyer.be | Zoeken',
    },
};

const sitemap = {
    title: {
        befr: 'Flyer.be | Sitemap',
        benl: 'Flyer.be | Sitemap',
    },

    description: {
        befr: 'Flyer.be | Sitemap',
        benl: 'Flyer.be | Sitemap',
    },
};

const metas = {
    gadgets: {
        home: {
            title: {
                befr: 'Des gadgets et des objets publicitaires personnalisés de qualité - Gadgets Flyer.be',
                benl: 'Gepersonaliseerde gadgets en relatiegeschenken van hoge kwaliteit - Gadgets Flyer.be',
            },

            description: {
                befr: 'Découvrez notre large gamme de gadgets et des objets publicitaire de qualité que vous pouvez personnaliser avec votre propre logo ou message. Différenciez votre business et améliorer votre marketing avec Gadgets Flyer.be',
                benl: 'Ontdek ons ruim aanbod aan relatiegeschenken en promotionele artikelen die u kunt aanpassen met uw eigen logo of boodschap. Zet uw bedrijf in de kijker en versterk uw marketing met Gadgets Flyer.be',
            },
        },

        catalog: {
            title: 'Flyer.be | {catalog}',
        },

        subcatalog: {
            title: 'Flyer.be | {subcatalog}',
        },

        product: {
            title: 'Flyer.be | {product}',
            description: '',
        },

        search,
        sitemap,
    },

    office: {
        home: {
            title: {
                befr: 'Produits office et fourniture de bureau de qualité à bas prix - Office Flyer.be',
                benl: 'Kwalitatief hoogwaardige kantoorartikelen en -benodigdheden tegen lage prijzen - Kantoor Flyer.be',
            },

            description: {
                befr: "Flyer.be vous propose l'essentiel de fournitures de bureaux pour votre entreprise. Restez toujours bien équipé afin de maintenir votre activité ou business avec les meilleures fournitures de bureaux et faites-vous livrer maintenant.",
                benl: 'Flyer.be biedt u de essentiële kantoorartikelen aan voor uw bedrijf. Zorg dat uw bedrijf altijd goed uitgerust is met de beste kantoorbenodigdheden. Makkelijk bestelbaar en afgeleverd bij u op kantoor.',
            },
        },

        catalog: {
            title: 'Flyer.be | {catalog}',
        },

        subcatalog: {
            title: 'Flyer.be | {subcatalog}',
        },

        product: {
            title: 'Flyer.be | {product}',
            description: '',
        },

        search,
        sitemap,
    },
};

export default metas;
