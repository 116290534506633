import upperFirst from 'lodash/upperFirst';
import dynamic from 'next/dynamic';
import { useI18n } from '@src/locales';
import StyledWrapper from './home.styled';

const Banners = dynamic(() => import('./Banners'), { ssr: true });
const Categories = dynamic(() => import('./Categories'), { ssr: true });
const PromoBanners = dynamic(() => import('./PromoBanners'), { ssr: true });
const ProductsList = dynamic(() => import('@src/components/ProductList'), { ssr: true });

const Home = ({ home }) => {
    const { t } = useI18n();

    return (
        <StyledWrapper fluid>
            <Banners />

            <ProductsList
                type="popular"
                title={t('popularProducts.text')}
                products={home.popular.data}
            />

            <PromoBanners />

            <ProductsList
                type="recommended"
                title={t(`recommendedProducts${upperFirst(process.env.NEXT_PUBLIC_SHOP_NAME)}`)}
                products={home.recommended.data}
            />

            <Categories />
        </StyledWrapper>
    );
};

export default Home;
