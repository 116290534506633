import Head from 'next/head';
import { useRouter } from 'next/router';
import wrapper from '@src/redux/store';
import { getStaticData } from '@src/server/layout';
import MainLayout from '@src/layouts/main';
import HomeContainer from '@src/containers/Partner/Home';
import Metas from '@src/utils/metas';
import { isDev, LANDING_LOCALES } from '@src/utils';
import { useGtagDataLayer } from '@src/utils/hooks';
import { getHomeData } from '@src/server/home';

const shop = process.env.NEXT_PUBLIC_SHOP_NAME;

const Home = () => {
    const { locale, asPath } = useRouter();

    const title = Metas[shop].home.title[locale];
    useGtagDataLayer({ title, path: `/${locale}${asPath}` });

    return (
        <>
            <Head>
                <title>{isDev}{title}</title>
                <meta name="description" content={Metas[shop].home.description[locale]} />
                <link rel="canonical" href={`${process.env.NEXT_PUBLIC_HOST}/${locale}`} />
                <link
                    rel="alternate"
                    href={`${process.env.NEXT_PUBLIC_HOST}${locale}`}
                    hrefLang={LANDING_LOCALES[locale].toLowerCase()}
                />
            </Head>

            <HomeContainer />
        </>
    );
};

Home.getLayout = (page) => <MainLayout>{page}</MainLayout>;

export const getStaticProps = wrapper.getStaticProps((store) => async ({ locale }) => {
    try {
        if (locale === 'default') {
            return { notFound: true };
        }

        await getStaticData({ store });
        await getHomeData({ store });

        return { props: {} };
    } catch (error) {
        console.log(error);
        return { props: {} };
    }
});

export default Home;
